import { Injectable, signal } from '@angular/core';
import {
  FeatureFlags,
  FeatureFlagValues,
} from '@client-portal/models/feature-flag';
import { ClpEnvironment } from '@client-portal/environment';
import { objectEntries } from '@client-portal/functions/utils';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {
  private _initialValues: FeatureFlags = {
    ...this._env.featureFlags,
    shiftBookingType: undefined,
  };

  public values: FeatureFlagValues = objectEntries(this._initialValues).reduce(
    (acc, [key, value]) => {
      // @ts-ignore
      acc[key] = signal(value);
      return acc;
    },
    {} as FeatureFlagValues,
  );

  constructor(private _env: ClpEnvironment) {}

  public updateFeatureFlag<T extends keyof FeatureFlags>(
    feature: T,
    value: FeatureFlags[T],
  ): void {
    this.values[feature].set(value);
  }
}
